import "./scss/app.scss";
import 'animate.css/animate.compat.css';
require ('jquery/src/jquery')
require ('./scripts/core.min.js')
require ('lightgallery/src/js/lightgallery.js')
require ('./scripts/googlemaps.js')

import { Toast } from 'bootstrap';

var Isotope = require('isotope-layout');

var userAgent = navigator.userAgent.toLowerCase(),
  initialDate = new Date(),

  $document = $(document),
  $window = $(window),
  $html = $("html"),

  isDesktop = $html.hasClass("desktop"),
  isIE = userAgent.indexOf("msie") !== -1 ? parseInt(userAgent.split("msie")[1], 10) : userAgent.indexOf("trident") !== -1 ? 11 : userAgent.indexOf("edge") !== -1 ? 12 : false,
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
  windowReady = false,
  isTouch = "ontouchstart" in window,
  isNoviBuilder = false,
  plugins = {
    rdNavbar:       $(".rd-navbar"),
    swiper:         $(".swiper-slider"),
    lightGallery:            $( '[data-lightgallery="group"]' ),
    lightGalleryItem:        $( '[data-lightgallery="item"]' ),
    lightDynamicGalleryItem: $( '[data-lightgallery="dynamic"]' ),
    isotope:                 $( '.isotope-wrap' ),
  };

/**
 * Initialize All Scripts
 */
 $(function() {

  isNoviBuilder = window.xMode;


  // RD Navbar
  if (plugins.rdNavbar.length) {
    var
      navbar = plugins.rdNavbar,
      aliases = { '-': 0, '-sm-': 576, '-md-': 768, '-lg-': 992, '-xl-': 1200, '-xxl-': 1600 },
      responsive = {};

    for (var alias in aliases) {
      var link = responsive[aliases[alias]] = {};
      if (navbar.attr('data' + alias + 'layout')) link.layout = navbar.attr('data' + alias + 'layout');
      if (navbar.attr('data' + alias + 'device-layout')) link.deviceLayout = navbar.attr('data' + alias + 'device-layout');
      if (navbar.attr('data' + alias + 'hover-on')) link.focusOnHover = navbar.attr('data' + alias + 'hover-on') === 'true';
      if (navbar.attr('data' + alias + 'auto-height')) link.autoHeight = navbar.attr('data' + alias + 'auto-height') === 'true';
      if (navbar.attr('data' + alias + 'stick-up-offset')) link.stickUpOffset = navbar.attr('data' + alias + 'stick-up-offset');
      if (navbar.attr('data' + alias + 'stick-up')) link.stickUp = navbar.attr('data' + alias + 'stick-up') === 'true';
      if (isNoviBuilder) link.stickUp = false;
      else if (navbar.attr('data' + alias + 'stick-up')) link.stickUp = navbar.attr('data' + alias + 'stick-up') === 'true';
    }

    plugins.rdNavbar.RDNavbar({
      anchorNav: !isNoviBuilder,
      stickUpClone: (plugins.rdNavbar.attr("data-stick-up-clone") && !isNoviBuilder) ? plugins.rdNavbar.attr("data-stick-up-clone") === 'true' : false,
      responsive: responsive,
      callbacks: {
        onStuck: function () {
          var navbarSearch = this.$element.find('.rd-search input');

          if (navbarSearch) {
            navbarSearch.val('').trigger('propertychange');
          }
        },
        onDropdownOver: function () {
          return !isNoviBuilder;
        },
        onUnstuck: function () {
          if (this.$clone === null)
            return;

          var navbarSearch = this.$clone.find('.rd-search input');

          if (navbarSearch) {
            navbarSearch.val('').trigger('propertychange');
            navbarSearch.trigger('blur');
          }

        }
      }
    });
  }

  /**
   * getSwiperHeight
   * @description  calculate the height of swiper slider basing on data attr
   */
   function getSwiperHeight(object, attr) {
    var val = object.attr("data-" + attr),
      dim;

    if (!val) {
      return undefined;
    }

    dim = val.match(/(px)|(%)|(vh)$/i);

    if (dim.length) {
      switch (dim[0]) {
        case "px":
          return parseFloat(val);
        case "vh":
          return $(window).height() * (parseFloat(val) / 100);
        case "%":
          return object.width() * (parseFloat(val) / 100);
      }
    } else {
      return undefined;
    }
  }

/**
   * toggleSwiperInnerVideos
   * @description  toggle swiper videos on active slides
   */
  function toggleSwiperInnerVideos(swiper) {
    var prevSlide = $(swiper.slides[swiper.previousIndex]),
        nextSlide = $(swiper.slides[swiper.activeIndex]),
        videos;

    prevSlide.find("video").each(function () {
      this.pause();
    });

    videos = nextSlide.find("video");
    if (videos.length) {
      videos.get(0).play();
    }
  }

  /**
   * toggleSwiperCaptionAnimation
   * @description  toggle swiper animations on active slides
   */
  function toggleSwiperCaptionAnimation(swiper) {
    var prevSlide = $(swiper.container),
        nextSlide = $(swiper.slides[swiper.activeIndex]);
      prevSlide
          .find("[data-caption-animate]")
          .each(function () {
            var $this = $(this);
            $this
                .removeClass("animated")
                .removeClass($this.attr("data-caption-animate"))
                .addClass("not-animated");
          });

      nextSlide
          .find("[data-caption-animate]")
          .each(function () {
            var $this = $(this),
                delay = $this.attr("data-caption-delay");

            setTimeout(function () {
              $this
                  .removeClass("not-animated")
                  .addClass($this.attr("data-caption-animate"))
                  .addClass("animated");
            }, delay ? parseInt(delay) : 0);
          });
  }

  /**
   * makeParallax
   * @description  create swiper parallax scrolling effect
   */
  function makeParallax(el, speed, wrapper, prevScroll) {
    var scrollY = window.scrollY || window.pageYOffset;

    if (prevScroll != scrollY) {
      prevScroll = scrollY;
      el.addClass('no-transition');
      el[0].style['transform'] = 'translate3d(0,' + -scrollY * (1 - speed) + 'px,0)';
      el.height();
      el.removeClass('no-transition');

      if (el.attr('data-fade') === 'true') {
        var bound = el[0].getBoundingClientRect(),
            offsetTop = bound.top * 2 + scrollY,
            sceneHeight = wrapper.outerHeight(),
            sceneDevider = wrapper.offset().top + sceneHeight / 2.0,
            layerDevider = offsetTop + el.outerHeight() / 2.0,
            pos = sceneHeight / 6.0,
            opacity;
        if (sceneDevider + pos > layerDevider && sceneDevider - pos < layerDevider) {
          el[0].style["opacity"] = 1;
        } else {
          if (sceneDevider - pos < layerDevider) {
            opacity = 1 + ((sceneDevider + pos - layerDevider) / sceneHeight / 3.0 * 5);
          } else {
            opacity = 1 - ((sceneDevider - pos - layerDevider) / sceneHeight / 3.0 * 5);
          }
          el[0].style["opacity"] = opacity < 0 ? 0 : opacity > 1 ? 1 : opacity.toFixed(2);
        }
      }
    }
    requestAnimationFrame(function () {
      makeParallax(el, speed, wrapper, prevScroll);
    });
  }
/**
   * Swiper 3.1.7
   * @description  Enable Swiper Slider
   */
 if (plugins.swiper.length) {
    
  var i;
  for (i = 0; i < plugins.swiper.length; i++) {
    var s = $(plugins.swiper[i]);
    var pag = s.find(".swiper-pagination"),
      next = s.find(".swiper-button-next"),
      prev = s.find(".swiper-button-prev"),
      bar = s.find(".swiper-scrollbar"),
      parallax = s.parents('.rd-parallax').length,
      swiperSlide = s.find(".swiper-slide");

    for (var j = 0; j < swiperSlide.length; j++) {
      var $this = $(swiperSlide[j]),
        url;

      if (url = $this.attr("data-slide-bg")) {
        $this.css({
          "background-image": "url(" + url + ")",
          "background-size": "cover"
        })
      }
      if ($this.attr("data-brightness")) {
        $this.addClass("slide-" + $this.attr("data-brightness"));
      }
    }

    swiperSlide.end()
      .find("[data-caption-animate]")
      .addClass("not-animated")
      .end()
      .swiper({
        autoplay: s.attr('data-autoplay') ? s.attr('data-autoplay') === "false" ? undefined : s.attr('data-autoplay') : 5000,
        direction: s.attr('data-direction') ? s.attr('data-direction') : "horizontal",
        effect: s.attr('data-slide-effect') ? s.attr('data-slide-effect') : "slide",
        speed: s.attr('data-slide-speed') ? s.attr('data-slide-speed') : 600,
        keyboardControl: s.attr('data-keyboard') === "true",
        mousewheelControl: s.attr('data-mousewheel') === "true",
        mousewheelReleaseOnEdges: s.attr('data-mousewheel-release') === "true",
        nextButton: next.length ? next.get(0) : null,
        prevButton: prev.length ? prev.get(0) : null,
        pagination: pag.length ? pag.get(0) : null,
        paginationClickable: pag.length ? pag.attr("data-clickable") !== "false" : false,
        paginationBulletRender: pag.length ? pag.attr("data-index-bullet") === "true" ? function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        } : null : null,
        scrollbar: bar.length ? bar.get(0) : null,
        scrollbarDraggable: bar.length ? bar.attr("data-draggable") !== "false" : true,
        scrollbarHide: bar.length ? bar.attr("data-draggable") === "false" : false,
        loop: s.attr('data-loop') !== "false",
        onTransitionStart: function (swiper) {
          toggleSwiperInnerVideos(swiper);
        },
        onTransitionEnd: function (swiper) {
          toggleSwiperCaptionAnimation(swiper);
        },
        onInit: function (swiper) {
          toggleSwiperInnerVideos(swiper);
          toggleSwiperCaptionAnimation(swiper);
          var swiperParalax = s.find(".swiper-parallax");

          for (var k = 0; k < swiperParalax.length; k++) {
            var $this = $(swiperParalax[k]),
              speed;

            if (parallax && !isIEBrows && !isMobile) {
              if (speed = $this.attr("data-speed")) {
                makeParallax($this, speed, s, false);
              }
            }
          }
          $(window).on('resize', function () {
            swiper.update(true);
          })
        }
      });

    $(window)
      .on("resize", function () {
        var mh = getSwiperHeight(s, "min-height"),
          h = getSwiperHeight(s, "height");
        if (h) {
          s.css("height", mh ? mh > h ? mh : h : h);
        }
      })
      .trigger("resize");
  }
}

  /**
   * @desc Initialize the gallery with set of images
   * @param {object} itemsToInit - jQuery object
   * @param {string} [addClass] - additional gallery class
   */
   function initLightGallery ( itemsToInit, addClass ) {
    if ( !isNoviBuilder ) {
      $( itemsToInit ).lightGallery( {
        thumbnail: $( itemsToInit ).attr( "data-lg-thumbnail" ) !== "false",
        selector: "[data-lightgallery='item']",
        autoplay: $( itemsToInit ).attr( "data-lg-autoplay" ) === "true",
        pause: parseInt( $( itemsToInit ).attr( "data-lg-autoplay-delay" ) ) || 5000,
        addClass: addClass,
        mode: $( itemsToInit ).attr( "data-lg-animation" ) || "lg-slide",
        loop: $( itemsToInit ).attr( "data-lg-loop" ) !== "false"
      } );
    }
  }

  /**
   * @desc Initialize the gallery with dynamic addition of images
   * @param {object} itemsToInit - jQuery object
   * @param {string} [addClass] - additional gallery class
   */
  function initDynamicLightGallery ( itemsToInit, addClass ) {
    if ( !isNoviBuilder ) {
      $( itemsToInit ).on( "click", function () {
        $( itemsToInit ).lightGallery( {
          thumbnail: $( itemsToInit ).attr( "data-lg-thumbnail" ) !== "false",
          selector: "[data-lightgallery='item']",
          autoplay: $( itemsToInit ).attr( "data-lg-autoplay" ) === "true",
          pause: parseInt( $( itemsToInit ).attr( "data-lg-autoplay-delay" ) ) || 5000,
          addClass: addClass,
          mode: $( itemsToInit ).attr( "data-lg-animation" ) || "lg-slide",
          loop: $( itemsToInit ).attr( "data-lg-loop" ) !== "false",
          dynamic: true,
          dynamicEl: JSON.parse( $( itemsToInit ).attr( "data-lg-dynamic-elements" ) ) || []
        } );
      } );
    }
  }

  /**
   * @desc Initialize the gallery with one image
   * @param {object} itemToInit - jQuery object
   * @param {string} [addClass] - additional gallery class
   */
  function initLightGalleryItem ( itemToInit, addClass ) {
    if ( !isNoviBuilder ) {
      $( itemToInit ).lightGallery( {
        selector: "this",
        addClass: addClass,
        counter: false,
        youtubePlayerParams: {
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
          controls: 0
        },
        vimeoPlayerParams: {
          byline: 0,
          portrait: 0
        }
      } );
    }
  }
  
  // lightGallery
  if (plugins.lightGallery.length) {
    for (var i = 0; i < plugins.lightGallery.length; i++) {
      initLightGallery(plugins.lightGallery[i]);
    }
  }

  // lightGallery item
  if (plugins.lightGalleryItem.length) {
    // Filter carousel items
    var notCarouselItems = [];

    for (var z = 0; z < plugins.lightGalleryItem.length; z++) {
      if (!$(plugins.lightGalleryItem[z]).parents('.owl-carousel').length &&
        !$(plugins.lightGalleryItem[z]).parents('.swiper-slider').length &&
        !$(plugins.lightGalleryItem[z]).parents('.slick-slider').length) {
        notCarouselItems.push(plugins.lightGalleryItem[z]);
      }
    }

    plugins.lightGalleryItem = notCarouselItems;

    for (var i = 0; i < plugins.lightGalleryItem.length; i++) {
      initLightGalleryItem(plugins.lightGalleryItem[i]);
    }
  }

  // Dynamic lightGallery
  if (plugins.lightDynamicGalleryItem.length) {
    for (var i = 0; i < plugins.lightDynamicGalleryItem.length; i++) {
      initDynamicLightGallery(plugins.lightDynamicGalleryItem[i]);
    }
  }

  // Isotope
  if ( plugins.isotope.length ) {
    for ( var i = 0; i < plugins.isotope.length; i++ ) {
      var
        wrap = plugins.isotope[ i ],
        filterHandler = function ( event ) {
          event.preventDefault();
          for ( var n = 0; n < this.isoGroup.filters.length; n++ ) this.isoGroup.filters[ n ].classList.remove( 'active' );
          this.classList.add( 'active' );
          this.isoGroup.isotope.arrange( { filter: this.getAttribute( "data-isotope-filter" ) !== '*' ? '[data-filter*="' + this.getAttribute( "data-isotope-filter" ) + '"]' : '*' } );
        },
        resizeHandler = function () {
          this.isoGroup.isotope.layout();
        };

      wrap.isoGroup = {};
      wrap.isoGroup.filters = wrap.querySelectorAll( '[data-isotope-filter]' );
      wrap.isoGroup.node = wrap.querySelector( '.isotope' );
      wrap.isoGroup.layout = wrap.isoGroup.node.getAttribute( 'data-isotope-layout' ) ? wrap.isoGroup.node.getAttribute( 'data-isotope-layout' ) : 'masonry';
      wrap.isoGroup.isotope = new Isotope( wrap.isoGroup.node, {
        itemSelector: '.isotope-item',
        layoutMode: wrap.isoGroup.layout,
        filter: '*',
        columnWidth: ( function() {
          if ( wrap.isoGroup.node.hasAttribute('data-column-class') ) return wrap.isoGroup.node.getAttribute('data-column-class');
          if ( wrap.isoGroup.node.hasAttribute('data-column-width') ) return parseFloat( wrap.isoGroup.node.getAttribute('data-column-width') );
        }() )
      } );

      for ( var n = 0; n < wrap.isoGroup.filters.length; n++ ) {
        var filter = wrap.isoGroup.filters[ n ];
        filter.isoGroup = wrap.isoGroup;
        filter.addEventListener( 'click', filterHandler );
      }

      window.addEventListener( 'resize', resizeHandler.bind( wrap ) );
    }
  }

  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#343c66",
        "text": "#cfcfe8"
      },
      "button": {
        "background": "#f71559"
      }
    },
    "theme": "classic",
    "content": {
      "message": "Ez az oldal sütiket (cookie) használ a böngésződben a maximális felhasználói élmény érdekében.",
      "dismiss": "Megértettem",
      "link": "Részletek itt",
      "href": "www.katiebeauty.hu/cookie-policy"
    }
  });

  Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
    if ( plugins.isotope.length ) {
      for ( var i = 0; i < plugins.isotope.length; i++ ) {
        var wrap = plugins.isotope[ i ];
        wrap.isoGroup.isotope.layout();
      }
    };
  });

});
